import axios from 'axios'
import wx from 'weixin-js-sdk'
import {eventBus} from "./bus";

export default {
    shareCommon: function (title, desc, imgUrl, link) {
        // 微信二次分享会在链接后面拼接参数，导致签名失败
        function getQueryString(name) { // 根据字段看网址是否拼接&字符串
            let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
            let r = window.location.search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2])
            }
            return null
        }

        function appSource() {
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isiOS) {
                return "ios";
            } else {
                return "andriod";
            }
        }

        // 判断是否为二次分享，拼接了其他的参数
        let from = getQueryString('from');
        let appInstall = getQueryString('appinstall');
        let sec = getQueryString('sec');
        let timeKey = getQueryString('timekey');
        if (from || appInstall || sec || timeKey) { // 假如拼接上了
            // alert('二次的')
            // window.location.href = 'http://view.buzaochao.com/'
        }
        // 用处理过可签名的链接请求微信配置文件
        let _url;
        if (appSource() === 'ios') {
            _url = sessionStorage.getItem('firstPage')
        } else {
            _url = window.location.href.split('#')[0]
        }

        let formData = new FormData();
        formData.append('url', _url);
        axios.post('https://view.xitiger.com/php/public/index/WechatShare', formData).then(function (res) {
            let getMsg = res.data.data;
            wx.config({
                debug: false, // 生产环境需要关闭debug模式
                appId: getMsg.appId, // appId通过微信服务号后台查看
                timestamp: getMsg.timestamp, // 生成签名的时间戳
                nonceStr: getMsg.nonceStr, // 生成签名的随机字符串
                signature: getMsg.signature, // 签名
                jsApiList: [
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage'
                ]
            });
            wx.error(function () {
            });
            wx.ready(function () {
                // let link = window.location.href
                // if (link.indexOf('?') !== -1) {
                //   link = window.location.href + '&merch_sn=' + localStorage.getItem('merch_sn')
                // } else {
                //   link = window.location.href + '?merch_sn=' + localStorage.getItem('merch_sn')
                // }
                // 分享到朋友圈
                wx.onMenuShareTimeline({
                    title: title, // 分享标题
                    desc: desc, // 分享描述
                    link: link, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                    }
                });
                // 分享给朋友
                wx.onMenuShareAppMessage({
                    title: title, // 分享标题
                    desc: desc, // 分享描述
                    link: link, // 分享链接
                    imgUrl: imgUrl, // 分享图标
                    success: function () {
                    }
                })
            })
        })
    },

    // 扫码添加至商品库
    onScan: function (index) {
        // 判断是否为二次分享，拼接了其他的参数
        let from = this.getQueryString('from');
        let appInstall = this.getQueryString('appinstall');
        let sec = this.getQueryString('sec');
        let timeKey = this.getQueryString('timekey');
        if (from || appInstall || sec || timeKey) { // 假如拼接上了
            // alert('二次的')
            // window.location.href = 'http://view.buzaochao.com/'
        }
        // 用处理过可签名的链接请求微信配置文件
        let _url;
        if (this.appSource() === 'ios') {
            _url = sessionStorage.getItem('firstPage')
        } else {
            _url = window.location.href.split('#')[0]
        }
        let formData = new FormData();
        formData.append('url', _url);
        axios.post('https://view.xitiger.com/php/public/index/WechatShare', formData).then(function (res) {
            let getMsg = res.data.data;
            wx.config({
                debug: false, // 生产环境需要关闭debug模式
                appId: getMsg.appId, // appId通过微信服务号后台查看
                timestamp: getMsg.timestamp, // 生成签名的时间戳
                nonceStr: getMsg.nonceStr, // 生成签名的随机字符串
                signature: getMsg.signature, // 签名
                jsApiList: [
                    'scanQRCode'
                ]
            });
            wx.error(function () {
            });
            wx.ready(function () {
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        eventBus.$emit('scanSuccess', result, index);
                    }
                });
            })
        })
    },

    // 扫码添加商品到商户
    onScanCodeToAddGoodsForShop: function () {
        // 判断是否为二次分享，拼接了其他的参数
        let from = this.getQueryString('from');
        let appInstall = this.getQueryString('appinstall');
        let sec = this.getQueryString('sec');
        let timeKey = this.getQueryString('timekey');
        if (from || appInstall || sec || timeKey) { // 假如拼接上了
            // alert('二次的')
            // window.location.href = 'http://view.buzaochao.com/'
        }
        // 用处理过可签名的链接请求微信配置文件
        let _url;
        if (this.appSource() === 'ios') {
            _url = sessionStorage.getItem('firstPage')
        } else {
            _url = window.location.href.split('#')[0]
        }
        let formData = new FormData();
        formData.append('url', _url);
        axios.post('https://view.xitiger.com/php/public/index/WechatShare', formData).then(function (res) {
            let getMsg = res.data.data;
            wx.config({
                debug: false, // 生产环境需要关闭debug模式
                appId: getMsg.appId, // appId通过微信服务号后台查看
                timestamp: getMsg.timestamp, // 生成签名的时间戳
                nonceStr: getMsg.nonceStr, // 生成签名的随机字符串
                signature: getMsg.signature, // 签名
                jsApiList: [
                    'scanQRCode'
                ]
            });
            wx.error(function () {
            });
            wx.ready(function () {
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        eventBus.$emit('scanSuccess', result);
                    },
                    fail: function () {
                        eventBus.$emit('scanFail');
                    }
                });
            })
        })
    },

    // 微信二次分享会在链接后面拼接参数，导致签名失败
    getQueryString: function (name) {
        // 根据字段看网址是否拼接&字符串
        let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let r = window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2])
        }
        return null
    },

    appSource: function () {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
            return "ios";
        } else {
            return "andriod";
        }
    }
}
